import * as React from 'react';
import { Auth } from 'aws-amplify';
import { Button } from 'reactstrap';
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import { Redirect } from "react-router-dom";


export interface HomeProps {
  user: object
}

export default class Home extends React.Component<HomeProps> {
  public render(){
    if (this.props.user["user"]) {
      return <Redirect to="/create"/>
    }
    return (
      <div className="text-center">
        <img src="home.png" alt="Skale-5"/>
          <h5 className="mt-4">Please login using your Google account</h5>
        <Button title="GoogleSignIn" color="primary" onClick={() => { Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google }); }} >Sign In with Google</Button>
      </div>
    )
  }
}