import * as React from 'react';
import { HashRouter as Router, Route } from 'react-router-dom';
import { Container, Navbar, NavbarBrand, NavItem, NavLink, Button } from 'reactstrap';

import './App.scss';
import Create from './Create';
import DisplaySecret from './DisplaySecret';
import Download from './Download';
import Upload from './Upload';
import Home from './Home';
import { useTranslation } from 'react-i18next';
import Amplify, { Auth, Hub } from 'aws-amplify';

const cognito_user_pool_id = process.env.REACT_APP_COGNITO_USER_POOL_ID 
  ? `${process.env.REACT_APP_COGNITO_USER_POOL_ID}`
  : '';

const cognito_web_client_id = process.env.REACT_APP_COGNITO_WEB_CLIENT_ID 
  ? `${process.env.REACT_APP_COGNITO_WEB_CLIENT_ID}`
  : '';

const cognito_domain = process.env.REACT_APP_COGNITO_DOMAIN 
  ? `${process.env.REACT_APP_COGNITO_DOMAIN}`
  : '';

const callback = process.env.REACT_APP_CALLBACK 
  ? `${process.env.REACT_APP_CALLBACK}`
  : '';

Amplify.configure({
  Auth: {
    region: "eu-west-1",
    userPoolId: cognito_user_pool_id,
    userPoolWebClientId: cognito_web_client_id,
    oauth: {
      domain: cognito_domain,
      scope: ['email', 'openid'],
      redirectSignIn: callback,
      redirectSignOut: callback + "logout/",
      responseType: 'token' 
    }
  }
});

class App extends React.Component {
  state = { user: null };

  componentDidMount() {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          this.setState({ user: data });
          break;
        case "signOut":
          this.setState({ user: null });
          break;
        case "customOAuthState":
          this.setState({ customState: data });
      }
    });

    Auth.currentAuthenticatedUser()
      .then((user) => this.setState({ user: user }))
      .catch(() => this.setState({ user: null }))
  }

  public render() {
    if (window.location.pathname === '/logout/') window.location.replace(window.location.origin + "/#/")
    const { user } = this.state;
    let signOutButton, navItemUpload, navItemCreate;
    if (user !== null) {
      navItemCreate =  <NavItem className="mt-1"><NavLink href="/#/create">Message</NavLink></NavItem>
      navItemUpload =  <NavItem className="mt-1"><NavLink href="/#/upload">Upload</NavLink></NavItem>
      signOutButton = <Button color="danger" onClick={() => Auth.signOut()}>Sign Out</Button>
    }
    return (
      <Router>
        <Navbar className="d-flex justify-content-between" color="dark" dark={true} expand="md">
          <div className="d-flex">
            <NavbarBrand href="/">
              <img height="40" alt="" src="logo.png" />
            </NavbarBrand>
            {navItemCreate}
            {navItemUpload}
          </div>
          <div className="d-flex">
            {signOutButton}
          </div>
        </Navbar>
        <Container className="margin">
        <Routes user={this.state.user}/>
        </Container>
        <Attribution />
      </Router>
    );
  }
}

const Routes = (user: any) => {
  return (
    <div>
      <Route exact={true} path="/" component={()=><Home user={user}/>}/>
      <Route exact={true} path="/upload" component={Upload}/>
      <Route exact={true} path="/create" component={Create}/>
      <Route exact={true} path="/s/:key/:password" component={DisplaySecret} />
      <Route exact={true} path="/c/:key/:password" component={DisplaySecret} />
      <Route exact={true} path="/s/:key" component={DisplaySecret} />
      <Route exact={true} path="/c/:key" component={DisplaySecret} />
      <Route exact={true} path="/f/:key/:password" component={Download} />
      <Route exact={true} path="/f/:key" component={Download} />
      <Route exact={true} path="/d/:key" component={Download} />
      <Route exact={true} path="/d/:key/:password" component={Download} />
    </div>
  );
};

const Attribution = () => {
  const { t } = useTranslation();
  return (
    <Container className="text-center footer-copyright mt-5">
      <div className="text-muted small footer">
        {t('Created by')}{' '}
        <a href="https://github.com/jhaals/yopass">{t('Johan Haals')}</a>
      </div>
    </Container>
  );
};

export default App;
